import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

const Post = ({ data }) => {
  const { mdx } = data // also has site
  const { title, author, date } = mdx.fields

  return (
    <Layout>
      <SEO title={title} />
      <article>
        <h1>{title}</h1>
        <div>
          <h3>
            {author && <span>by {author}</span>}{' '}
            {date && <span>on {date}</span>}
          </h3>
        </div>
        <MDXRenderer>{mdx.code.body}</MDXRenderer>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        slug
      }
      code {
        body
      }
    }
  }
`

export default Post
